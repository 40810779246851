/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import Header from 'parts/Header';
import About from 'parts/About';
import AllTeam from 'parts/AllTeam';
import Footer from 'parts/Footer';

import Data from 'json/landingPage.json';
import AboutHero from 'parts/AboutHero';

export default class AboutPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <AboutHero />
        <About {...this.props} />
        <AllTeam data={Data.team} />
        <Footer />
      </>
    );
  }
}
