/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';
import Data from 'json/landingPage.json';
import AllProcess from './AllProcess';

export default function About() {
  return (
    <div className="container mx-auto pt-5 pb-10">
      <Fade bottom>
        <div className="flex flex-wrap">
          <div className="w-full p-4">
            <div className="bg-primary-color pt-20 pb-1 px-6 md:px-10 rounded-lg">
              <h3 className=" text-2xl lg:text-4xl text-center text-white font-bold mb-4">The Process We Follow</h3>
              <p className="text-left md:text-center text-white px-6 lg:px-64 mb-4">
                At justAsemester, our design process is driven by creativity,
                collaboration, and a deep understanding of your brand.
                We deliver exceptional design solutions that captivate
                audiences and drive meaningful results.
              </p>
              <AllProcess data={Data.process} />
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
