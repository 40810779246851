/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

import Fade from 'react-reveal/Fade';
import Team from 'assets/images/ser1.svg';
import UX from 'assets/images/uiux.svg';
import Content2 from 'assets/images/ser4.svg';
import Content3 from 'assets/images/ser5.svg';

export default function ServicesDetail() {
  return (
    <>
      <div className="container mx-auto pt-5 pb-10">
        <Fade bottom>
          <h1 className="text-5xl text-theme-blue pb-5 text-center font-bold">Design & Development</h1>
        </Fade>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-4">
            <div className="services-bg py-20 px-10 rounded-lg">
              <h2 className="text-3xl active-link font-bold mb-4">Web and Mobile App Development:</h2>
              <ul className="list-disc ml-5">
                <li className="text-2xl">User-Centric Approach:</li>
                <p className="font-light">
                  We prioritize your audience by creating websites and mobile apps that are
                  user-friendly, ensuring that visitors have a smooth and satisfying experience.
                </p>
                <li className="text-2xl mt-4">Visual Excellence:</li>
                <p className="font-light">
                  Our development team combines aesthetics with functionality to design
                  visually appealing digital products, making sure your online presence stands out.
                </p>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-4 order-first lg:order-last">
            <div className="p-6 rounded-lg">
              <img src={Team} alt="Hero" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-4">
            <div className="p-6 rounded-lg">
              <img src={UX} alt="Hero" />
            </div>
          </div>
          <div className="w-full md:w-1/2 p-4">
            <div className="services-bg py-20 px-10 rounded-lg">
              <h2 className="text-3xl active-link font-bold mb-4">UI/UX Design:</h2>
              <ul className="list-disc ml-5">
                <li className="text-2xl">Intuitive Design:</li>
                <p className="font-light">
                  Our expert designers specialize in crafting intuitive user
                  interfaces (UI) that make navigation effortless, enhancing
                  the overall user experience (UX).
                </p>
                <li className="text-2xl mt-4">Usability & Aesthetics:</li>
                <p className="font-light">
                  We strike a perfect balance between usability and
                  aesthetics, ensuring that your digital products not only
                  work well but also look appealing, leaving a positive
                  impression on users.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="services-bg">
        <div className="container mx-auto pt-10 pb-10">
          <Fade bottom>
            <h1 className="text-5xl text-theme-blue pb-5 text-center font-bold">Digital Marketing</h1>
          </Fade>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 p-4">
              <div className="bg-white py-20 px-10 rounded-lg">
                <h2 className="text-3xl active-link font-bold mb-4">Digital Marketing Strategy & Services:</h2>
                <ul className="list-disc ml-5">
                  <li className="text-2xl">Comprehensive Approach:</li>
                  <p className="font-light">
                    We offer a holistic approach to digital marketing,
                    encompassing services like SEO, social media marketing, and
                    pay-per-click advertising to ensure your brand reaches its
                    target audience across various online channels.
                  </p>
                  <li className="text-2xl mt-4">Target Engagement:</li>
                  <p className="font-light">
                    Our strategies are tailored to engage your specific target
                    market effectively, utilizing data-driven insights and tactics to
                    maximize engagement, conversions, and brand visibility.
                  </p>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-4 order-first lg:order-last">
              <div className="p-6 rounded-lg">
                <img src={Content2} alt="Hero" />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 p-4">
              <div className="p-6 rounded-lg">
                <img src={Content3} alt="Hero" />
              </div>
            </div>
            <div className="w-full md:w-1/2 p-4">
              <div className="bg-white py-20 px-10 rounded-lg">
                <h2 className="text-3xl active-link font-bold mb-4">Digital PR & Branding Services:</h2>
                <ul className="list-disc ml-5">
                  <li className="text-2xl">Enhanced Visibility:</li>
                  <p className="font-light">
                    We boost your brand visibility by leveraging online
                    platforms, influencers, and strategic partnerships, ensuring
                    that your brand is seen by the right audience at the right time.
                  </p>
                  <li className="text-2xl mt-4">Positive Brand Image:</li>
                  <p className="font-light">
                    Our services not only increase visibility but also focus on
                    creating a positive and lasting impression of your brand,
                    enhancing your reputation and credibility in the digital space
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
