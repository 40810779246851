/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import BrandIcon from 'parts/BrandIcon';
import Button from 'elements/Button';
import { FaInstagram, FaLinkedin, FaFacebook } from 'react-icons/fa';

export default function Footer() {
  return (
    <div className="bg-primary-color border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-3/4 sm:w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              LET&apos;S EMBARK ON A JOURNEY TO REALIZE YOUR VISION, CRAFTING AN ASTOUNDING
              {' '}
              <br />
              DIGITAL PRESENCE THAT TRULY CAPTURES YOUR AMBITIONS.
            </p>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-white pt-4 pb-2">
              For Business
            </h1>
            <p className="sm:text-lg lg:text-lg md:text-sm text-gray-400 font-light">
              info@justasemester.co.uk
            </p>
            {/* <p className="text-lg text-gray-400 font-light">
              Klaten, Central Java, Indonesia
            </p> */}
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-white pt-4 pb-2">
              Social
            </h1>
            <Button href="https://www.instagram.com/justasemester.uk/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              <FaInstagram className="mr-2 mt-1" />
              Instagram
            </Button>
            <Button href="https://www.linkedin.com/company/justasemester/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              <FaLinkedin className="mr-2 mt-1" />
              LinkedIn
            </Button>
            <Button href="https://www.facebook.com/justasemester.uk" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              <FaFacebook className="mr-2 mt-1" />
              Facebook
            </Button>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            © 2024 justasemester. All rights reserved
          </p>

        </div>
      </div>
    </div>
  );
}
