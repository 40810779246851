import React from 'react';
import Lottie from 'lottie-react';
import fav from 'assets/images/loader.json';

const LoaderPage = () => (
  <div className="fixed inset-0 flex items-center justify-center">
    {/* Set height to cover the entire viewport */}
    <div className="h-full w-full flex items-center justify-center">
      <div className="animation-wrapper">
        <Lottie
          animationData={fav}
          loop
          autoplay
        />
      </div>
    </div>
  </div>
);

export default LoaderPage;
