// src/components/Features.jsx
import React from 'react';

import learing from 'assets/images/learing.svg';
import liveTraining from 'assets/images/live_training.svg';
import certificate from 'assets/images/certificate.svg';
import success from 'assets/images/success.svg';
import quick from 'assets/images/quick.svg';
import fee from 'assets/images/fee.svg';
import guar from 'assets/images/guar.svg';
import best from 'assets/images/best.svg';

const features = [
  { icon: learing, text: 'Learn From Home' },
  { icon: liveTraining, text: 'Live Trainer' },
  { icon: certificate, text: 'Get Certificate' },
  { icon: success, text: 'Highest Success Rate' },
  { icon: quick, text: 'Quick Learning of Skills' },
  { icon: fee, text: 'Affordable Fee' },
  { icon: guar, text: 'Guaranteed Success' },
  { icon: best, text: 'Best in Town' },
];

const AboutDetail = () => (
  <div className="bg-gray-50 py-20 mb-24 sm:mb-18 xl:mb-16">
    <div className="container mx-auto">
      <h1 className="text-5xl text-theme-blue text-center font-bold">Why Choose Us</h1>

      <p className="font-light text-lg text-gray-400 text-center mb-12 sm:mb-5 xl:mb-0">
        OUR UNIQUE SELLING POINTS
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {features.map((feature) => (
          <div key={feature.text} className="text-center m-4 p-4">
            <img src={feature.icon} alt={feature.text} className="mx-auto mb-2" style={{ width: '50px', height: '50px' }} />
            <p>{feature.text}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default AboutDetail;
