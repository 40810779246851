/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

export default function AllTeam({ data }) {
  return (
    <section className="container mx-0 sm:mx-auto">
      <h3 className="text-4xl text-center font-bold mb-10">Meet our team</h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-y-8 gap-x-32 sm:gap-14 lg:gap-10 mx-16 justify-items-center">
        {
                    data.map((item, index) => (
                      <Fade bottom delay={200 * index}>
                        <div>
                          <div className="flex flex-col w-44 h-60 sm:w-56 sm:h-72 rounded-xl shadow-xl border border-light-theme-purple justify-center transform transition duration-500 hover:scale-105">
                            <div className="flex justify-center xl:mb-5">
                              <img src={item.imageUrl} alt="Team Member" className="flex w-32 h-32 rounded-full" />
                            </div>
                            <h2 className="text-theme-blue text-center text-xl">{item.name}</h2>
                            <p className="font-light text-gray-400 text-center mb-3">{item.position}</p>
                            <div className="absolute w-full flex justify-center items-center opacity-0 transition-opacity duration-300 hover:opacity-100">
                              <a href={item.facebookUrl} target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebookF className="text-white w-6 h-6 mx-1" /></a>
                              <a href={item.instaUrl} target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram className="text-white w-6 h-6 mx-1" /></a>
                              <a href={item.linkedinUrl} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><FaLinkedinIn className="text-white w-6 h-6 mx-1" /></a>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    ))
                }
      </div>
    </section>
  );
}
