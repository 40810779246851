/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';
import backgroundImage from 'assets/images/abouty-hero-shape.svg';

export default function AboutHero() {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'left',
  };
  return (
    <section className="hero">
      <Fade bottom>
        <div className="w-full xl:pl-12 sm:pr-2 mt-8 text-center" style={backgroundStyle}>
          <h5 className="text-4xl text-theme-blue font-black leading-tight mb-5">
            justAsemester
          </h5>
          <p className="text-2xl font-bold text-theme-blue leading-relaxed mb-5">
            Your Trusted Partners For Digital Solutions
          </p>
          <p className=" text-5xl active-link font-medium leading-tight mb-2">
            We are the Performance Marketing Agency of Media brands.
          </p>
        </div>
      </Fade>
    </section>
  );
}
