/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Fade from 'react-reveal/Fade';

export default function AllProcess({ data }) {
  return (
    <section className="container mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
        {data.map((item, index) => (
          <Fade bottom delay={200 * index}>
            <div className="w-full max-w-xs sm:max-w-none md:max-w-none lg:max-w-xs h-72 md:w-80 lg:w-full">
              <div className="bg-white rounded-xl shadow-xl border border-light-theme-purple hover:scale-105 transition duration-500 transform">
                <div className="flex items-center justify-center p-6">
                  <img src={item.imageUrl} alt="Team Member" className="w-20 h-20" />
                </div>
                <div className="text-center">
                  <h5 className="text-theme-blue font-bold text-xl">{item.name}</h5>
                  <p className="px-2 mb-4 text-sm">{item.position}</p>
                </div>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </section>
  );
}
