/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';

import LandingPage from 'pages/LandingPage';
import ProjectPage from 'pages/ProjectPage';
import ProjectDetailPage from 'pages/ProjectDetailPage';
import AboutPage from 'pages/AboutPage';
import DiscussProjectPage from 'pages/DiscussProjectPage';
import NotFoundPage from 'pages/NotFoundPage';
import ServicesPage from 'pages/ServicesPage';
import Loader from './pages/LoaderPage';

import 'assets/css/styles.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  // Let create async method to fetch fake data
  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    };

    fakeDataFetch();
  }, []);
  return isLoading ? (<Loader />) : (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/courses" component={ProjectPage} />
      <Route exact path="/services" component={ServicesPage} />
      <Route exact path="/courses/:id" component={ProjectDetailPage} />
      <Route exact path="/about" component={AboutPage} />
      <Route exact path="/discuss-project" component={DiscussProjectPage} />
      <Route path="" component={NotFoundPage} />
    </Switch>
  );
}

export default App;
