/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import Header from 'parts/Header';
import Discuss from 'parts/Discuss';
import Footer from 'parts/Footer';

import ServicesDetail from 'parts/ServicesDetail';

export default class ServicesPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <ServicesDetail />
        <Discuss {...this.props} />
        <Footer />
      </>
    );
  }
}
